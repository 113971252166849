.MuiOutlinedInput-root input {
  color: white;
  outline-color: white;
}

.MuiFormControl-root.MuiFormControl-fullWidth:not(:first-child)  {
  margin-top: 10px;
}

.MuiOutlinedInput-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: white;
  color: white;
  border-radius: 40px;
}

.MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline span {
  border-color: rgb(23,	193,	188);
}

label.MuiFormLabel-root  {
  color:  rgba(255,	255,	255, 0.5);
  font-size: 14px;
  line-height: 24px;
  padding-left: 5px;
}

label.MuiFormLabel-root span  {
  display: none;
}

label.MuiFormLabel-root.Mui-focused  {
  color:  rgb(23,	193,	188);
}


.main-entrance form {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.custom-input {
  border-radius: 40px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .custom-input,
.custom-textarea {
    font-size: 12px;
    line-height: 14px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .custom-input:hover:not(.focus-visible) input,
.custom-textarea:hover:not(.focus-visible) input {
    outline-width: 2px;
  }
}
.custom-input:focus-within input,
.custom-textarea:focus-within input {
  outline-width: 2px;
  outline-color: #17c1bc;
}
.custom-input.is-value, .custom-input:focus-within,
.custom-textarea.is-value,
.custom-textarea:focus-within {
  color: #000000;
}
.custom-input.is-value .custom-input__label,
.custom-input.is-value .custom-textarea__label, .custom-input:focus-within .custom-input__label,
.custom-input:focus-within .custom-textarea__label,
.custom-textarea.is-value .custom-input__label,
.custom-textarea.is-value .custom-textarea__label,
.custom-textarea:focus-within .custom-input__label,
.custom-textarea:focus-within .custom-textarea__label {
  display: none;
}
.custom-input label,
.custom-textarea label {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.custom-input input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font: inherit;
  color: currentColor;
  border: none;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: 1px solid #ffffff;
  outline-offset: -1px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.custom-input input:-webkit-autofill,
.custom-input textarea:-webkit-autofill,
.custom-textarea input:-webkit-autofill,
.custom-textarea textarea:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #574f69;
          box-shadow: inset 0 0 0 1000px #574f69;
  -webkit-text-fill-color: #ffffff;
}
.custom-input input {
  padding: 18px;
  color: #ffffff;
  text-align: center;
}
.invalid-form .custom-input input {
  outline-color: #e86969;
  outline-width: 2px;
}
.custom-input input::-webkit-input-placeholder, .custom-textarea input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(217, 217, 217, 0.5);
}
.custom-input input::-moz-placeholder, .custom-textarea input::-moz-placeholder {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(217, 217, 217, 0.5);
}
.custom-input input:-ms-input-placeholder, .custom-textarea input:-ms-input-placeholder {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(217, 217, 217, 0.5);
}
.custom-input input::-ms-input-placeholder, .custom-textarea input::-ms-input-placeholder {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(217, 217, 217, 0.5);
}
.custom-input input::placeholder,
.custom-textarea input::placeholder {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(217, 217, 217, 0.5);
}

.custom-input.is-invalid,
.custom-textarea.is-invalid {
  color: red;
}
.custom-input.is-invalid input.focus-visible:focus,
.custom-input.is-invalid textarea.focus-visible:focus,
.custom-textarea.is-invalid input.focus-visible:focus,
.custom-textarea.is-invalid textarea.focus-visible:focus {
  color: #000000;
}

.btn {
  position: relative;
  color: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0 0;
  padding: 22px 18px;
  background-color: #e86969;
  border: none;
  border-radius: 40px;
  outline: none;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}
.btn:active {
  opacity: 0.7;
}
.btn svg {
  -webkit-transition: -webkit-transform 0.1s ease-in;
  transition: -webkit-transform 0.1s ease-in;
  -o-transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}
.btn svg:active {
  -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
          transform: scale(0.9);
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover:not(.focus-visible) {
    background-color: #17c1bc;
  }
  .btn:hover:not(.focus-visible) span {
    opacity: 0.5;
  }
}
.btn.focus-visible:focus {
  background-color: #574f69;
}
.btn.focus-visible:focus span {
  opacity: 0.5;
}
.btn.focus-visible:focus {
  outline: 2px solid #e86969;
  outline-offset: 2px;
}
.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 767px) {
  .btn {
    font-size: 10px;
    line-height: 13px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn--full-width:hover:not(.focus-visible) {
    background-color: #17c1bc;
  }
}
